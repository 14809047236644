@import "variables";
@import "jscrollpane";
@import "feed";
@import "framework/framework";
@import "../../node_modules/magnific-popup/dist/magnific-popup";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";

html {
	background: $white;
	font-family: "madrone-std", sans-serif;
	font-size: 1rem;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 13px;
	}
}

.hide{
	display: none;
}

body {
	color: $black;
}

h1,h2,h3{
	font-family: "madrone-std", sans-serif;
}

h2{
	font-size: 1.75rem;
}

a {
	color: $black;

	@include hover-focus {
		color: $black;
	}
	&.btn {
		display: block;
		padding: 0.5rem 1.25rem 0.375rem 1.25rem;
		border-radius: 3.125rem;
		line-height: 1;
		border: solid 0.125rem transparent;
		transition: all .3s;
		font-size: 1rem;
		max-width: max-content;
		text-transform: uppercase;
		&.btn-green{
			color: #fff;
			background-color: $green;
			border-color: $green;
		}
		&.btn-white{
			background-color: $white;
			color: $black;
			border-color: $green;
			@include hover-focus{
				color: $green;
			}
		}
		@include hover-focus{
			transform: translateY(-0.125rem);
		}
	}
}

header {
	background-color: $white;
	padding: 1.25rem 0;
	.container{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		max-width: 87.5rem;
		nav{
			display: flex;
			justify-content: center;
			order: 3;
			width: 100%;
			margin-top: 1.25rem;
			flex-wrap: wrap;
			.nav-item{
				color: $black;
				text-transform: uppercase;
				font-size: 0.8rem;
				margin-right: 1.25rem;
				transition: all .3s;
				&:last-child{
					margin-right: 0;
				}
				@include hover-focus{
					transform: translateY(-0.125rem);
					color: $green;
				}
			}
		}
		.header-logo{
			display: block;
			order: 1;
			height: 2.5rem;
			margin-bottom: 1.25rem;
		}
		.section{
			order: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 0.625rem;
			width: 100%;
			.btn{
				margin-bottom: 0.625rem;
			}
			.social{
				display: flex;
				justify-content: center;
				margin: 0 auto;
				a{
					display: block;
					color: $green;
					font-size: 1.25rem;
					margin-right: 1.25rem;
					transition: all .3s;
					&:last-child{
						margin-right: 0;
					}
					@include hover-focus{
						transform: translateY(-0.125rem);
					}
				}
			}
		}
	}
	@media screen and (min-width:68.75rem) {
		.container{
			justify-content: space-between;
			
			flex-direction: row;
			align-items: space-between;
			.section{
				margin-top: 0;
				align-items: flex-end;
				width: max-content;
			}
			
			nav{
				order: 1;
			}
			.header-logo{
				order: 2;
				height: 1.875rem;
				margin-bottom: 0;
			}
			.section{
				order: 3;
				flex-direction: row;
				align-items: center;
				.btn{
					margin-bottom: 0;
					margin-right: 1.25rem;
				}
			}
		}
		
	}
	@include phone-up{
		.container{
			nav{
				order: 2;
				width: initial;
				margin-top: 0;
			}
		}
	}
}

main {
	section{
		&.hero{
			img{
				display: block;
				width: 100%;
				height: 25rem;
				object-fit: cover;
				object-position: center left; 
			}
			@include phone-up{
				img{
					height: 37.5rem;
				}
			}
			@include tablet-up{
				img{
					height: calc( 100vh - 6.875rem );
				}
			}
		}

		&.music{
			padding: 2.5rem 0;
			background-color: $white-d;
			
			color: $white;
			h2{
				text-transform: uppercase;
				color: $green;
				margin: 0 0 1.25rem 0;
			}
			h3{
				text-align: center;
				font-size: 3rem;
				margin: 0 0 rem(12);
				
				text-transform: uppercase;
				font-weight: 900;
				// color: #99c1b1;
				&.small{
					font-size: 2.25rem;
				}
				@media screen and (max-width: 800px){
					font-size: 2rem !important;
				}
			}
			.split{
				display: grid;
				justify-items: center;
				gap: 1.25rem;
				.cover{
					display: block;
					overflow: hidden;
					transition: all .3s;
					max-width: 100%;
					img{
						transition: all .3s;
						max-width: 100%;
					}
					@include hover-focus{
						// box-shadow: 0 0 1rem $green;
						img{
							transform: scale(1.025);
						}
					}
				}
				.content{
					max-width: 100%;
					img{
						display: block;
						margin-bottom: 1.25rem;
						max-width: 100%;
					}
					p{
						text-align: center;
						font-size: 1.125rem;
						margin-bottom: 0.625rem;
						text-transform: uppercase;
					}
					.music-links{
						display: flex;
						justify-content: center;
						@media screen and (max-width: 28.125rem){
							flex-wrap: wrap;
						}
						a{
							display: flex;
							justify-content: center;
							align-items: center;
							border: solid 0.125rem $green;
							background: $green;
							color: $white-d;
							width: 2.5rem;
							height: 2.5rem;
							border-radius: 2.5rem;
							margin-right: 0.625rem;
							transition: all .3s;
							i{
								font-size: 1.25rem;
							}
							img{
								width: 1.125rem;
								height: 1.125rem;
								margin: 0;
								display: block;
								object-fit: contain;
								transform: translateX(0.0625rem);
							}
							&:last-child{
								margin-right: 0;
							}
							@include hover-focus{
								transform: translateY(-0.125rem);
							}
						}
					}
				}
			}
			@include tablet-up{
				padding: 3.75rem 0;
				.container{
					.split{
						grid-template-columns: repeat(2, 1fr);
						align-items: center;
						.content{
							img{
								margin-bottom: 2.5rem;
							}
							p{
								margin-bottom: 1.875rem;
							}
						}
					}
				}
			}
		}

		&.videos{
			background-color: $green;
			padding: 2.5rem 0;
			.container{
				h2{
					margin: 0 0 1.25rem 0;
					text-align: center;
					text-transform: uppercase;
					color: $white;
				}
				.videos-grid{
					display: grid;
					gap: 2.5rem;
					justify-items: center;
					.video{
						img{
							margin: 0 auto 1.25rem;
							object-fit: cover;
						}
						p{
							color: #fff;
							font-size: .75rem;
							text-transform: uppercase;
							text-align: center;
							line-height: 1.4;
						}
					}
				}
			} 
			@include tablet-down{
				.videos-grid{
					width: 100%;
					max-width: 31.25rem;
					margin: 0 auto;
				}
				.video{
					width: 100%;
					img{
						width: 100%;
					}
				}
			}
			@include tablet-up{
				padding: 5rem 0;
				.container{
					h2{
						margin-bottom: 4.375rem;
					}
					.videos-grid{
						grid-template-columns: repeat(2, 1fr);
						gap: 1.25rem;
						.video{
							width: 100%;
							img{
								height: 20.625rem;
							}
							p{
								text-align: left;
							}
						}
					}
				}
			}
		}

		&.tour{
			background-image: url('../img/bg-tour.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-attachment: fixed;
			position: relative;
			.bg{
				display: none;
			}
			.wrapper{
				position: relative;
				padding: 2.5rem 0;
				.container{
					h2{
						text-transform: uppercase;
						color: $green;
						font-size: 2.375rem;
						font-weight: 900;
						margin: 0 0 2.5rem 0;
					}
					#events{
						display: grid;
						gap: 2.5rem;
						.event{
							text-transform: uppercase;
							font-size: 1rem;
							display: grid;
							gap: 0.375rem;
							line-height: 1.4;
							font-family: sans-serif;
						}
					}
				}
			}
			@include tablet-up{
				.wrapper{
					padding: 5rem 0;
					h2{
						margin-bottom: 3.75rem;
						font-size: 2rem;
					}
					.container{
						// padding-left: 20%;
						#events{
							.event{
								display: flex;
								justify-content: space-between;
								align-items: center;
								.event-date{
									flex: 1;
								}
								.event-location{
									flex: 2;
								}
								.event-venue{
									flex: 3; 
								}
								.btn{
									flex: 1;
								}
							}
						}
					}
				}
			}
		}

		&.shop{
			position: relative;
			background-color: $white-d;
			.wrapper{
				padding: 2.5rem 0;
				.container{
					h2{
						text-transform: uppercase;
						color: $green;
						text-align: left;
						font-size: 2.375rem;
						margin: 0 0 2.5rem 3rem;
					}
				}
			}

			.btn{
				margin: rem(15) auto 0;
				background-color: $green;
				color: $white;
				font-weight: bold;
			}
			.row{
				display: flex;
				justify-content: center;
				.item{
					padding: rem(30);
					flex: 1;
					text-align: center;
				}
				
				@include tablet-down{
					flex-direction: column;
					align-items: center;
					.item{
						max-width: rem(280);
						width: 100%;
					}
				}
			}
			
			@include tablet-up{
				.wrapper{
					padding: 5rem 0;
					h2{
						margin-bottom: 3.75rem;
						font-size: 2rem;
					}
				}
			}
		}

		&.about{
			padding: 2.5rem 0;
			background-color: $green;
			color: $white;

			.container{
				.split{
					display: grid;
					gap: 1.25rem;
					img{
						display: block;
						margin: 0;
						height: 37.5rem;
						object-fit: cover;
						max-width: 100%;
					}
					.content{
						max-width: 100%;
						h2{
							text-transform: uppercase;
							color: $white;
							font-weight: 900;
							font-size: 2.375rem;
							margin: 0 0 1.25rem 0;
						}
						.bio-wrapper{
							max-height: 34.75rem;
							overflow-y: scroll;
							overflow-x: hidden;
							padding-right: 1.25rem;
							p{
								margin-bottom: 1.25rem;
								font-size: 1rem;
								font-family: sans-serif;
								line-height: 1.4;
							}
							.jspVerticalBar {
								width: 0.375rem;
								.jspTrack {
									background-color: $green;
									.jspDrag {
										background-color: $white;
									}
								}
							}
						}
					}
				}
				.instagram-feed{
					padding-top: 2.5rem;
					h3{
						text-transform: uppercase;
						color: $white;
						font-weight: 900;
						font-size: 2.375rem;
						margin: 0;
					}
				}
			}
			@include tablet-up {
				padding: 6.25rem 0 5rem;
				.container{
					.split{
						grid-template-columns: repeat(2, 1fr);
					}
				}
			}
		}
	}
}

footer {
	padding: 1.25rem 0;
	text-transform: uppercase;
	font-size: 0.6875rem;
	text-align: center;
	line-height: 1.6;
	background-color: $green;
	color: $white;

	.contacts{
		display: flex;
		width: 100%;
		max-width: rem(600);
		>div{
			flex: 1;
			padding: rem(10);
		}
		a{
			font-size: 1.5em;
			font-weight: bold;
			@media screen and (max-width: 28.125rem){
				font-size: 1.2em;
			}
		}
	}

	.container{
		display: flex;
		flex-direction: column;
		align-items: center;
		.footer-logo{
			display: block;
			margin-bottom: 1.25rem;
		}
		a{
			color: inherit;
			@include hover-focus{
				color: $white-d;
			}
		}
	}

	.copyright{
		width: 100%;
		max-width: rem(680);
		font-size: 0.6875rem;
		font-family: sans-serif;
		a{
			display: inline-block;
		}
	}
}

.slick-slider{
	position: relative;
	.slick-arrow{
		background-color: transparent;
		border: none;
		font-size: 1.875rem;
		position: absolute;
		top: calc(50% - 0.9375rem);
		z-index: 999;
		color: #fff;
		&.slick-prev{
			left: 0;
			@include tablet-up{
				left: -2.5rem;
			}
		}
		&.slick-next{
			right: 0;
			@include tablet-up{
				right: -2.5rem;
			}
		}
	}
}

.popup{
	position: relative;
	width: rem(800);
	max-width: 100%; 
	margin: rem(30) auto;
	.mfp-close{
		color: white;
	}
	.btn{
		margin: rem(20) auto 0;
	}
}